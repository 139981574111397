import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { AsPropWarning } from "../components/AsPropWarning";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Pipe = makeShortcode("Pipe");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Visually Hidden" description="React component to provide visually hidden text for screen readers" mdxType="SEO" />
    <h1 {...{
      "id": "visually-hidden"
    }}>{`Visually Hidden`}</h1>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/visually-hidden"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/visually-hidden`}</a></li>
      <li parentName="ul">{`Origin: `}<a parentName="li" {...{
          "href": "https://snook.ca/archives/html_and_css/hiding-content-for-accessibility"
        }}>{`https://snook.ca/archives/html_and_css/hiding-content-for-accessibility`}</a></li>
      <li parentName="ul">{`Further reading: `}<a parentName="li" {...{
          "href": "https://a11yproject.com/posts/how-to-hide-content/"
        }}>{`https://a11yproject.com/posts/how-to-hide-content/`}</a></li>
    </ul>
    <p>{`Provides text for screen readers that is visually hidden. It is the logical opposite of the `}<inlineCode parentName="p">{`aria-hidden`}</inlineCode>{` attribute.`}</p>
    <p>{`In the following example, screen readers will announce "Save" and will ignore the icon; the browser displays the icon and ignores the text.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<button>
  <VisuallyHidden>Save</VisuallyHidden>
  <svg aria-hidden width="32" height="32">
    <path d="M16 18l8-8h-6v-8h-4v8h-6zM23.273 14.727l-2.242 2.242 8.128 3.031-13.158 4.907-13.158-4.907 8.127-3.031-2.242-2.242-8.727 3.273v8l16 6 16-6v-8z"></path>
  </svg>
</button>
`}</code></pre>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/visually-hidden`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/visually-hidden`}</inlineCode>{`. Then import the component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/visually-hidden
# or
yarn add @reach/visually-hidden
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import VisuallyHidden from "@reach/visually-hidden";
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "visuallyhidden"
    }}>{`VisuallyHidden`}</h3>
    <h4 {...{
      "id": "visuallyhidden-props"
    }}>{`VisuallyHidden Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#visuallyhidden-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#visuallyhidden-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "visuallyhidden-as"
    }}>{`VisuallyHidden as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`VisuallyHidden`}</inlineCode>{` what element to render. Defaults to `}<inlineCode parentName="p">{`span`}</inlineCode>{`.`}</p>
    <AsPropWarning mdxType="AsPropWarning" />
    <h5 {...{
      "id": "visuallyhidden-children"
    }}>{`VisuallyHidden children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`The content you want to be visually hidden.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      